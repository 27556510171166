//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-572:_4072,_3536,_4224,_1828,_5352,_3404,_9148,_7520;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-572')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-572', "_4072,_3536,_4224,_1828,_5352,_3404,_9148,_7520");
        }
      }catch (ex) {
        console.error(ex);
      }